<template>
    <div class="page-vip g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ pageName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-wrap">
                <div class="page-side">
                    <div class="title" @click="showMenu = !showMenu">
                        <img src="@/assets/img/vip.png" alt="">
                        会员中心
                    </div>
                    <div class="menu" :class="{show:showMenu}">
                        <div class="menu-item" v-for="(item,index) in menu" :key="index">
                            <div class="menu-item-tit"
                                 :class="{open:item.isOpen}"
                                 @click="item.isOpen = !item.isOpen">
                                <h3>{{item.title}}</h3>
                                <div class="arr"></div>
                            </div>
                            <div class="menu-sub" v-if="item.isOpen">
                                <div class="menu-sub-item"
                                     @click="jumpTo(sub)"
                                     :class="{active:curPage === sub.title}"
                                     v-for="(sub,subIndex) in item.children" :key="subIndex">
                                    {{sub.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            showMenu:false,
            curPage:'个人信息',
            pageName:'',
            menu:[
                {
                    title:'资料管理',
                    isOpen:false,
                    children:[
                        {
                            title:'个人信息',
                            path:'/vip'
                        },
                        {
                            title:'收货信息管理',
                            path:'/addressForGoods'
                        },
                        // {
                        //     title:'收票地址管理',
                        //     path:'/addressForBill'
                        // },
                        // {
                        //     title:'发票信息管理',
                        //     path:'/billInfo'
                        // },
                        {
                            title:'账号密码管理',
                            path:'/password'
                        }
                    ]
                },
                {
                    title:'订单管理',
                    isOpen:false,
                    children:[
                        {
                            title:'我的订单',
                            path:'/myOrderForGoods'
                        },
                        {
                            title:'我的积分订单',
                            path:'/orderForBill'
                        },
                    ]
                },
                {
                    title:'余额管理',
                    isOpen:false,
                    children:[
                        {
                            title:'我的余额',
                            path:'/balance'
                        },
                    ]
                },
                {
                    title:'积分管理',
                    isOpen:false,
                    children:[
                        {
                            title:'我的积分',
                            path:'/myIntegral'
                        },
                        {
                            title:'兑换明细',
                            path:'/myExchange'
                        },
                        {
                            title:'积分明细',
                            path:'/myHistory'
                        },
                    ]
                },
                {
                    title:'消息管理',
                    isOpen:false,
                    children:[
                        {
                            title:'站内通知',
                            path:'/Message'
                        },
                    ]
                },
            ]
        }
    },
    methods:{
        jumpTo:function (data) {
            console.log(data)
            if(this.$route.path != data.path){
                this.$router.push(data.path)
            }
        },
        renderCur:function (route) {
            this.pageName = route.meta.title
            this.menu.map(item => {
                item.children.map(d => {
                    if (d.path === route.path){
                        this.curPage = d.title
                        item.isOpen = true
                    }
                })
            })
        }
    },
    mounted() {
        this.renderCur(this.$route)
    },
    watch:{
        '$route'(val){
            this.renderCur(val)
        }
    }
}
</script>

<style scoped>

</style>
